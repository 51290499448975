var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MUpload',_vm._g(_vm._b({key:_vm.renderCount,class:_vm.disabled && !_vm.preview ? 'fileListDisabled' : '',style:(_vm.disabled ? { opacity: 0.5 } : {}),attrs:{"headers":_vm.headers,"action":_vm.uploadUrl,"multiple":_vm.allowMultiFileSelect,"name":"file","default-file-list":_vm.fileList,"show-upload-list":_vm.showUploadList,"open-file-dialog-on-click":!_vm.disabled,"before-upload":_vm.handleFileUpload,"disabled":_vm.disabled},on:{"remove":_vm.handleFileRemove,"change":_vm.handleFileStatusChange,"preview":_vm.handlePreview}},'MUpload',_vm.attrs,false),_vm.listeners),[((!_vm.disabled && !_vm.onlyList) || _vm.preview)?_vm._t("default",function(){return [(_vm.asLink)?_c('a',{staticClass:"text-neutral",on:{"click":_vm.handleButtonClick}},[_c('MIcon',{attrs:{"id":"paperclip-icon","name":"paperclip"}}),_c('span',{staticClass:"mx-2"},[_vm._v(_vm._s(_vm.buttonText))])],1):_c('MButton',{staticClass:"flex flex-1 items-center text-ellipsis",style:(!_vm.ignorePreferenceValidation
          ? {
              maxWidth: _vm.isHovering ? '220px' : '170px',
              transitionProperty: 'max-width',
              transitionDuration: '0.3s',
              transitionTimingFunction: 'cubic-bezier(0.42, 0, 0.58, 1)',
            }
          : {}),attrs:{"id":"attachment-btn","variant":"neutral","title":_vm.buttonText},on:{"click":_vm.handleButtonClick,"mouseenter":function($event){_vm.isHovering = true},"mouseleave":function($event){_vm.isHovering = false}}},[_c('MIcon',{attrs:{"name":"upload"}}),_c('div',{staticClass:"text-ellipsis box"},[_c('span',{staticClass:"mx-2"},[_vm._v(" "+_vm._s(_vm.buttonTextValue)+" ")])])],1)]}):_vm._e(),(_vm.currentPreviewImage)?_c('portal',{attrs:{"to":"image-preview"}},[_c('div',{staticClass:"image-preview"},[_c('div',{staticClass:"actions text-right mb-2"},[_c('MButton',{staticClass:"mr-2",attrs:{"shape":"circle","shadow":false,"variant":"transparent"},on:{"click":_vm.downloadImage}},[_c('MIcon',{staticClass:"text-white",attrs:{"name":"download","size":"lg"}})],1),_c('MButton',{attrs:{"shape":"circle","variant":"transparent","shadow":false},on:{"click":function($event){_vm.currentPreviewImage = null}}},[_c('MIcon',{staticClass:"text-white",attrs:{"name":"times","size":"lg"}})],1)],1),_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":_vm.currentPreviewImage.url}})])])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }