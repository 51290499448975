<template>
  <div class="ant-row ant-form-item ant-form-item-no-colon">
    <div class="ant-form-item-label read-only-label">
      <label :title="label">{{ label }}</label>
    </div>
    <div class="ant-form-item-control-wrapper">
      <!-- @TODO word break css apply -->
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FlotoFormItemReadOnly',
  props: {
    label: { type: String, default: undefined },
  },
}
</script>
<style lang="less" scoped>
.read-only-label {
  line-height: 26px !important;
}
.ant-form-item-control-wrapper {
  // white-space: pre-line;
  word-break: break-all;
}
</style>
