<template>
  <!-- specialy when custom trigger with multiple value we are using mselect dropdown for ex. technician picker with multiple -->
  <MSelect
    v-if="multiple && useMselect"
    :disabled="disabled"
    :options="innerOptions"
    class="w-full"
    :value="value"
    :placeholder="placeholder || $t('select')"
    v-bind="attrs"
    mode="multiple"
    @change="handleChange"
  >
    <template v-slot:option="{ option }">
      <slot name="menu-item" v-bind="{ item: option, selectItem: (i) => i }">
        <div class="dropdown-item flex items-center" href="javascript:;">
          <FlotoDot v-if="option.color" :bg="option.color" />
          <span class="text-ellipsis" style="display: block">
            {{ option.text || option.name || option.label }}
          </span>
          <MIcon v-if="option.disabled" name="times" />
        </div>
      </slot>
    </template>
  </MSelect>
  <span v-else>
    <template v-if="!isOpen">
      <slot
        name="trigger"
        :toggle="toggleDropdown"
        :hide="hideDropdown"
        :show="openDropdown"
        :current-item="selectedItem"
      >
        <MultipleTrigger
          v-if="multiple"
          :allow-clear="allowClear"
          :focus-event-brodcast="focusEventBrodcast"
          :selected-items="selectedItemFormMultiple"
          :disabled="disabled"
          :as-tag="asTag"
          :placeholder="placeholder"
          :options="innerOptions"
          v-bind="attrs"
          :input-classes="inputClasses"
          @click="toggleDropdown"
          @change="handleChange"
        />
        <DropdownTrigger
          v-else
          :toggle="toggleDropdown"
          :focus-event-brodcast="focusEventBrodcast"
          :selected-item="selectedItem"
          :allow-clear="allowClear"
          :text-only="textOnly"
          :disabled="disabled"
          :as-input="asInput"
          :size="size"
          :input-classes="inputClasses"
          :is-open="isOpen"
          :placeholder="placeholder || $t('select')"
          @reset="handleChange(undefined)"
        />
      </slot>
    </template>
    <template v-else>
      <FlotoScrollDropdown
        default-open
        :multiple="multiple"
        :options="optionsWithoutArchived"
        :filtered-options="filteredOptions"
        :disabled="disabled"
        v-bind="attrs"
        :value="value"
        :get-popup-container="getPopupContainer"
        v-on="listeners"
        @change="handleChange"
        @hide="hideDropdown"
      >
        <template v-slot:trigger="triggerSlotData">
          <slot
            name="trigger"
            v-bind="triggerSlotData"
            :current-item="selectedItem"
          >
            <MultipleTrigger
              v-if="multiple"
              :toggle="triggerSlotData.toggle"
              :allow-clear="allowClear"
              :focus-event-brodcast="focusEventBrodcast"
              :selected-items="selectedItemFormMultiple"
              :disabled="disabled"
              :as-tag="asTag"
              v-bind="attrs"
              :placeholder="placeholder"
              :options="innerOptions"
              @change="handleChange"
            />
            <DropdownTrigger
              v-else
              :toggle="triggerSlotData.toggle"
              :focus-event-brodcast="focusEventBrodcast"
              :selected-item="selectedItem"
              :is-open="isOpen"
              :allow-clear="allowClear"
              :text-only="textOnly"
              :disabled="disabled"
              :as-input="asInput"
              :size="size"
              :input-classes="inputClasses"
              :placeholder="placeholder || $t('select')"
              @reset="handleChange(undefined)"
            />
          </slot>
        </template>
        <template v-slot:before-menu>
          <div v-if="searchable || searchFn" class="my-2 px-2">
            <MInput
              ref="searchBox"
              v-model="searchQuery"
              :placeholder="$t('search')"
              @update="searchItems"
            >
              <template v-slot:suffix>
                <MIcon
                  v-if="loadingOptions"
                  name="spinner-third"
                  class="fa-spin text-neutral-light"
                />
                <MIcon v-else name="search" />
              </template>
            </MInput>
          </div>
          <div
            v-if="multiple && selectedItemFormMultiple.length"
            :class="{
              'mt-2': !(searchable || searchFn),
              'mt-0': searchable || searchFn,
              'px-2': true,
              'mb-2': true,
              'dropdown-selected-items-container':
                selectedItemFormMultiple.length > 4,
            }"
          >
            <div class="text-primary mb-1">
              {{ $tc('selected') }} {{ $tc('item', 2) }}
            </div>
            <MultipleTrigger
              :allow-clear="false"
              :selected-items="selectedItemFormMultiple"
              disabled
              as-tag
              can-remove-selected-items-pill
              display-all-selected-items-pill
              :options="innerOptions"
              :as-input="false"
              v-bind="attrs"
              :input-classes="inputClasses"
              @change="handleChange"
            />
            <MDivider class="mb-0 mt-1" />
          </div>
          <slot name="suggested-item"></slot>
        </template>
        <template v-slot:menu-item="{ item, selectItem }">
          <slot name="menu-item" v-bind="{ item, selectItem }">
            <div
              class="dropdown-item flex items-center"
              :class="{ 'font-semibold': searchQuery }"
              href="javascript:;"
              @click.stop="selectItem(item)"
            >
              <FlotoDot v-if="item.color" :bg="item.color" />
              <span class="mx-1 text-ellipsis" style="display: block">
                {{ item.text || item.name || item.label }}
              </span>
            </div>
          </slot>
        </template>
        <template v-if="$scopedSlots['no-data']" v-slot:no-data>
          <slot name="no-data" />
        </template>
        <slot name="menu"></slot>
      </FlotoScrollDropdown>
    </template>
  </span>
</template>

<script>
import Bus from '@utils/emitter'
import { getDropdownItemValue } from '@utils/value'
import Throttle from 'lodash/throttle'
import DropdownTrigger from './dropdown-trigger'
import MultipleTrigger from './tree-picker/multiple-trigger'

export default {
  name: 'FlotoDropdownPicker',
  components: { DropdownTrigger, MultipleTrigger },
  inheritAttrs: false,
  model: {
    event: 'change',
  },
  props: {
    // eslint-disable-next-line
    allowClear: { type: Boolean, default: true },
    mandatorySelection: { type: Boolean, default: false },
    searchable: { type: Boolean, default: false },
    searchFn: { type: Function, default: undefined },
    transformOptionsFn: { type: Function, default: undefined },
    textOnly: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    useMselect: { type: Boolean, default: false },
    defaultOpen: { type: Boolean, default: false },
    asInput: { type: Boolean, default: false },
    // eslint-disable-next-line
    size: { type: String, default: undefined },
    value: {
      type: [Number, String, Object, Array, Boolean],
      default: undefined,
    },
    disabled: { type: Boolean, default: false },
    asTag: { type: Boolean, default: false },
    placeholder: { type: String, default: undefined },
    inputClasses: { type: [String, Object, Array], default: undefined },
    immediateSearch: { type: Boolean, default: false },
    keepUnassigned: { type: Boolean, default: false },
    // on create form if value is archived then set default value
    validateArchivedValue: { type: Boolean, default: false },
    hiddenOptionsKeys: {
      type: Array,
      default() {
        return []
      },
    },
    visibleOptionsKeys: {
      type: Array,
      default() {
        return []
      },
    },
    focusEventBrodcast: { type: Boolean, default: false },
  },
  data() {
    this.getDropdownItemValue = getDropdownItemValue
    return {
      loadingOptions: false,
      isOpen: false,
      searchQuery: '',
      searchedOptions: null,
    }
  },
  computed: {
    optionsWithoutArchived() {
      return this.innerOptions.filter((o) => o.archived !== true)
    },
    filteredOptions() {
      if (this.searchQuery) {
        return this.optionsWithoutArchived.filter(
          (o) =>
            (o.text || o.name || o.label)
              .toLowerCase()
              .indexOf(this.searchQuery.toLowerCase()) >= 0
        )
      }
      return undefined
    },
    innerOptions() {
      let options = []
      if (this.searchedOptions) {
        options = this.searchedOptions
      } else {
        options = this.$attrs.options || this.options || []
        if (this.multiple && this.keepUnassigned === false) {
          options = options.filter((o) => getDropdownItemValue(o))
        }
        if (this.$attrs['additional-options']) {
          options = (this.$attrs['additional-options'] || []).concat(options)
        }
        if (this.mandatorySelection) {
          options = options.filter((o) => getDropdownItemValue(o) !== 0)
        }
        if (this.hiddenOptionsKeys.length) {
          options = options.filter(
            (o) =>
              this.hiddenOptionsKeys.indexOf(getDropdownItemValue(o)) === -1
          )
        }
        if (this.visibleOptionsKeys.length) {
          options = options.filter(
            (o) => this.visibleOptionsKeys.indexOf(getDropdownItemValue(o)) >= 0
          )
        }
      }
      // make archived options disabled
      if (this.multiple) {
        options = options.map((o) => ({
          ...o,
          disabled: o.archived || o.disabled,
        }))
      }
      return options
    },
    selectedItem() {
      if (this.innerOptions.length) {
        if (this.multiple) {
          return (this.value || []).map((id) =>
            this.innerOptions.find((o) => {
              const optionKey = getDropdownItemValue(o)
              return optionKey === id
            })
          )
        }
        return (this.innerOptions || []).find((p) => {
          const optionKey = p.id || p.value || p.key
          return optionKey === this.value || optionKey === parseInt(this.value)
        })
      }
      return undefined
    },
    selectedItemFormMultiple() {
      if (this.multiple && (this.selectedItem || []).length) {
        return this.selectedItem
          .filter((e) => e)
          .map((i) => getDropdownItemValue(i))
      }
      return []
    },
    listeners() {
      const { change, ...listeners } = this.$listeners
      return listeners
    },
    attrs() {
      const { options, ...attrs } = this.$attrs
      return attrs
    },
  },
  watch: {
    value: {
      handler(newValue, prevValue) {
        if (newValue && newValue !== prevValue) {
          this.searchItems()
        }
      },
    },
  },
  created() {
    if (this.immediateSearch) {
      this.searchItems()
    }
    if (this.value) {
      this.searchItems()
    }
    this.searchItems = Throttle(this.searchItems, 750)
    // set dynamic watch on value for handle archived value validation
    if (this.validateArchivedValue && !this.multiple && !this.immediateSearch) {
      this.$watch('value', {
        immediate: true,
        handler(newValue) {
          if (
            newValue &&
            (this.innerOptions.filter((o) => o.key === newValue).length === 0 ||
              this.innerOptions.find((o) => o.key === newValue).archived)
          ) {
            this.$nextTick(() => {
              let defaultValue = this.innerOptions.find((v) => v.default)
              if (!defaultValue) {
                defaultValue = { key: this.unassignedValue }
              } else if (!defaultValue && this.$attrs.mandatory) {
                defaultValue = this.innerOptions[0]
              }
              if (defaultValue) {
                this.$emit('change', defaultValue.key)
                this.$emit('blur')
              }
            })
          }
        },
      })
    }
    if (this.focusEventBrodcast) {
      const openPopover = (id) => {
        if (id === this.$attrs.id) {
          this.onDropdownShow(true)
        } else {
          this.hideDropdown(true)
        }
      }
      const closePopover = (id) => {
        if (id === this.$attrs.id) {
          this.hideDropdown(true)
        }
      }
      Bus.$on('app:popover:broadcast:open', openPopover)
      Bus.$on('app:popover:broadcast:close', closePopover)
      this.$once('hook:beforeDestroy', () => {
        Bus.$off('app:popover:broadcast:open', openPopover)
        Bus.$off('app:popover:broadcast:close', closePopover)
      })
    }
  },
  methods: {
    getPopupContainer() {
      const element = this.focusEventBrodcast
        ? this.$el.closest('.single-control')
        : this.$el.closest('.__panel')
      if (element) {
        return element
      }
      return document.body
    },
    // for form validation dummy change event call through refs
    dummyChange() {
      this.$emit('change', '')
    },
    searchItems() {
      if (this.searchFn) {
        this.loadingOptions = true
        let selectedItems = []
        if (this.selectedItem) {
          if (this.multiple) {
            selectedItems = this.selectedItem.filter(Boolean)
          } else {
            selectedItems = [this.selectedItem]
          }
        }
        this.searchFn(this.searchQuery, selectedItems)
          .then((data) => {
            let additionalOptions = []
            if (this.$attrs['additional-options']) {
              additionalOptions = this.$attrs['additional-options'] || []
            }
            this.searchedOptions = additionalOptions.concat(
              this.transformOptionsFn ? this.transformOptionsFn(data) : data
            )
          })
          .finally(() => (this.loadingOptions = false))
      }
    },
    openDropdown() {
      this.onDropdownShow()
    },
    hide() {
      this.hideDropdown()
    },
    hideDropdown(skipBroadcast = false) {
      this.isOpen = false
      if (this.focusEventBrodcast && this.$attrs.id && !skipBroadcast) {
        Bus.$emit('app:single:dropdown:close', this.$attrs.id)
      }
    },
    toggleDropdown() {
      if (!this.isOpen) {
        this.onDropdownShow()
        return
      }
      this.isOpen = !this.isOpen
    },
    onDropdownShow(skipBroadcast = false) {
      if (this.disabled) {
        return
      }
      this.isOpen = true
      this.searchQuery = ''
      setTimeout(() => {
        this.$refs.searchBox && this.$refs.searchBox.focus()
      }, 100)
      if (this.focusEventBrodcast && this.$attrs.id && !skipBroadcast) {
        Bus.$emit('app:single:dropdown:open', this.$attrs.id)
      }
    },
    handleChange(value) {
      if (this.multiple) {
        this.$emit(
          'selected',
          value.map((id) =>
            this.innerOptions.find(
              (o) => o.key === id || o.id === id || o.value === id
            )
          )
        )
        // If template picker and needs custom event for template selection
        if (this.selectedEventName) {
          this.$emit(
            this.selectedEventName,
            value.map((id) =>
              this.innerOptions.find(
                (o) => o.key === id || o.id === id || o.value === id
              )
            )
          )
        }
        this.$emit('change', value)
        this.$emit('blur')
      } else {
        this.$emit(
          'selected',
          this.innerOptions.find(
            (o) => o.key === value || o.id === value || o.value === value
          )
        )
        // If template picker and needs custom event for template selection
        if (this.selectedEventName) {
          this.$emit(
            this.selectedEventName,
            this.innerOptions.find(
              (o) => o.key === value || o.id === value || o.value === value
            )
          )
        }
        const finalValue =
          value || (value === 0 || value === '' ? value : this.unassignedValue)
        this.$emit('change', finalValue)
        this.$emit('blur')
      }
    },
    // handleSelectItem(item, selectItemFn) {
    //   if (this.multiple) {
    //     if ((this.value || []).indexOf(item.key) >= 0) {
    //       selectItemFn((this.value || []).filter((i) => i !== item.key))
    //     } else {
    //       selectItemFn([...(this.value || []), item.key])
    //     }
    //   } else {
    //     selectItemFn(item)
    //   }
    // },
  },
}
</script>

<style lang="less" scoped>
.dropdown-with-colors {
  .dropdown-item {
    @apply flex items-center cursor-pointer;
  }
}
</style>
