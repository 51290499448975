<template>
  <MAffix
    :style="noClick ? { pointerEvents: 'none' } : {}"
    @change="affixed = $event"
  >
    <MTab
      v-model="currentModule"
      v-bind="$attrs"
      :class="{ 'affixed-tab': affixed }"
      v-on="listeners"
    >
      <MTabPane v-for="m in modules" :key="m.moduleName" :tab="m.text" />
      <!-- <MTabPane key="problem" :tab="$tc('problem')" /> -->
      <!-- <MTabPane key="change" :tab="$tc('change')" /> -->
    </MTab>
  </MAffix>
</template>

<script>
import { LicenseComputed } from '@state/modules/license'
export default {
  name: 'FlotoModuleTabs',
  model: {
    event: 'change',
  },
  props: {
    value: { type: [String, Number], required: true },
    availableModules: {
      type: Array,
      default: undefined,
    },
    excludedModules: {
      type: Array,
      default() {
        return [this.$constants.USER, this.$constants.TASK]
      },
    },
    noClick: { type: Boolean, default: false },
  },
  data() {
    this.defaultModules = [
      {
        moduleName: this.$constants.REQUEST,
        text: this.$tc('request'),
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(this.$constants.REQUEST) >= 0,
      },
      {
        moduleName: this.$constants.PROBLEM,
        text: this.$tc('problem'),
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(this.$constants.PROBLEM) >= 0,
      },
      {
        moduleName: this.$constants.CHANGE,
        text: this.$tc('change'),
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(this.$constants.CHANGE) >= 0,
      },
      {
        moduleName: this.$constants.RELEASE,
        text: this.$tc('release'),
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(this.$constants.RELEASE) >= 0,
      },
      {
        moduleName: this.$constants.ASSET,
        text: this.$tc('asset'),
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(this.$constants.ASSET) >= 0,
      },
      {
        moduleName: this.$constants.ASSET_MOVEMENT,
        text: `${this.$tc('asset')} ${this.$tc('movement')}`,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(this.$constants.ASSET) >= 0,
      },
      {
        moduleName: this.$constants.KNOWLEDGE,
        text: this.$tc('knowledge'),
      },
      {
        moduleName: this.$constants.CONTRACT,
        text: this.$tc('contract'),
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(this.$constants.CONTRACT) >= 0,
      },
      {
        moduleName: this.$constants.PURCHASE,
        text: this.$tc('purchase'),
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(this.$constants.PURCHASE) >= 0,
      },
      {
        moduleName: this.$constants.PROJECT,
        text: this.$tc('project'),
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(this.$constants.PROJECT) >= 0,
      },
      { moduleName: this.$constants.USER, text: this.$tc('user') },
      {
        moduleName: this.$constants.TASK,
        text: this.$tc('task'),
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(this.$constants.REQUEST) >= 0,
      },
      {
        moduleName: this.$constants.USER_SURVEY,
        text: this.$tc('user_survey'),
      },
      {
        moduleName: this.$constants.AUTO_TICKET_CREATE,
        text: this.$tc('incident_scheduler'),
      },
      {
        moduleName: this.$constants.AUTO_TASK_CREATE,
        text: this.$tc('task_schedule'),
      },
      {
        moduleName: this.$constants.ADMIN,
        text: this.$tc('admin'),
      },
    ]
    return { affixed: false }
  },
  computed: {
    ...LicenseComputed,
    currentModule: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('change', v)
      },
    },
    modules() {
      let modules = this.defaultModules.filter((m) => {
        if (m.isModuleAvailable) {
          return m.isModuleAvailable(this.availableModulesInLicense)
        }
        return true
      })
      if (this.availableModules) {
        modules = modules.filter(
          (m) => this.availableModules.indexOf(m.moduleName) >= 0
        )
      }
      if (this.excludedModules) {
        modules = modules.filter(
          (m) => this.excludedModules.indexOf(m.moduleName) === -1
        )
      }
      return modules
    },
    listeners() {
      const { change, ...listeners } = this.$listeners
      return listeners
    },
  },
  watch: {
    currentModule: {
      immediate: true,
      handler(newValue, prevValue) {
        if (
          newValue !== prevValue &&
          this.value &&
          !(
            (this.modules || []).filter((m) => m.moduleName === this.value) ||
            []
          ).length
        ) {
          this.$emit('change', ((this.modules || [])[0] || {}).moduleName)
        }
      },
    },
  },
}
</script>
