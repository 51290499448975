var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlotoCrudContainer',_vm._b({ref:"list",attrs:{"as-table":"","scrollable":_vm.scrollable,"module-name":_vm.parentModuleName,"fetch-fn":_vm.fetchTicketFn,"delete-fn":_vm.deleteComputerFn,"columns":_vm.tableColumns,"selectable":_vm.selectable},on:{"selection-change":function($event){return _vm.$emit('selection-change', $event)}},scopedSlots:_vm._u([(_vm.searchable)?{key:"add-controls",fn:function(ref){
var refreshList = ref.refreshList;
var items = ref.items;
return [_c('MRow',{staticClass:"my-4"},[_c('MCol',{attrs:{"size":8}},[_c('FlotoSearchBar',{ref:"searchBar",attrs:{"allow-keywords":false,"allow-save-search":false,"persist-criteria":false,"module-name":_vm.parentModuleName,"exclude-search-params":_vm.handleExcludeSearchParams},on:{"change":function($event){return _vm.applyCriteria($event, refreshList)}}})],1),(_vm.allowManualPatchAction && _vm.mode === 'missing')?_c('MCol',{staticClass:"flex justify-end",attrs:{"size":4}},[_vm._t("add-controls",null,{"items":items})],2):_vm._e()],1)]}}:null,(_vm.allowBulkAction)?{key:"bulk-actions",fn:function(slotData){return [_c('BulkActions',_vm._b({attrs:{"module-name":_vm.parentModuleName,"mode":_vm.mode,"allow-manual-patch-action":_vm.allowManualPatchAction},on:{"delete":_vm.handleDelete}},'BulkActions',Object.assign({}, _vm.$attrs, slotData),false))]}}:null,{key:"form",fn:function(){return [_c('span')]},proxy:true},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_c('ResourceListTitle',_vm._b({attrs:{"name":item.name,"open-in-new-tab":"","link":_vm.$modules.getModuleRoute('computer', 'view', {
            params: { id: item.id, configType: _vm.moduleName },
          }),"internal-link":!_vm.disableRoute},scopedSlots:_vm._u([{key:"before-title",fn:function(){return [_c('FlotoDot',{staticClass:"cursor-pointer mr-2",attrs:{"bg":item.active ? '#6bda7b' : '#ffe000',"size":15}})]},proxy:true}],null,true)},'ResourceListTitle',_vm.$attrs,false))],1)]}},{key:"hostName",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(item.hostName || '---')+" ")])]}},{key:"ipAddress",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(item.ipAddress || '---')+" ")])]}},{key:"osName",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(item.osName || '---')+" ")])]}},{key:"agentVersion",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(item.agentVersion || '---')+" ")])]}},{key:"servicePack",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(item.servicePack || '---')+" ")])]}},{key:"architecture",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_c('ArchitecturePicker',{attrs:{"value":item.architecture,"as-input":false,"disabled":"","placeholder":'---'}})],1)]}},{key:"loggedOnUser",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[(item.loggedOnUser)?_c('FlotoUserDrawer',{attrs:{"user-id":item.loggedOnUser}}):_c('span',[_vm._v("---")])],1)]}},{key:"patchEnabled",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(item.patchEnabled || '---')+" ")])]}},{key:"healthType",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_c('SystemHealthPicker',{attrs:{"value":item.healthType,"as-input":false,"disabled":"","placeholder":'---'}})],1)]}},{key:"remoteOfficeId",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_c('RemoteOfficePicker',{attrs:{"value":item.remoteOfficeId,"as-input":false,"disabled":"","placeholder":'---'}})],1)]}},{key:"actions",fn:function(ref){
          var item = ref.item;
          var remove = ref.remove;
return [_c('td',{class:{
        'sticky-action': _vm.actionSticked.indexOf(item.id) >= 0,
        'hover-action-container': _vm.hoverableActions,
      }},[_vm._t("action",function(){return [_c('FlotoDeleteBtn',{attrs:{"message":_vm.$t('confirm_delete_item', {
              item: ("" + (_vm.$tc('computer'))),
            })},on:{"confirm":remove}})]},{"item":item,"stickAction":function () { return _vm.stickActionFor(item); },"unstickAction":function () { return _vm.unStickActionFor(item); }})],2)]}},{key:"default",fn:function(slotData){return [_vm._t("default",null,null,slotData)]}}],null,true)},'FlotoCrudContainer',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }