<template>
  <div
    class="bordered-actions"
    :class="{ active: isActive, [size]: true, 'action-hidden': disabled }"
    v-bind="$attrs"
    v-on="$listeners"
    @mouseenter="isMouseActive = true"
    @mouseleave="isMouseActive = false"
  >
    <div v-if="!disabled" class="control-action-div">
      <div class="internal-actions flex">
        <slot name="actions" />
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FlotoBorderedActions',
  props: {
    active: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    size: { type: String, default: 'default' },
  },
  data() {
    return { isMouseActive: false }
  },
  computed: {
    isActive() {
      if (this.disabled) {
        return false
      }
      return this.active || this.isMouseActive
    },
  },
}
</script>

<style lang="less" scoped>
.bordered-actions {
  @apply relative;

  &:not(.action-hidden) {
    @apply py-0 px-2;
  }

  .control-action-div {
    top: -37px;
    right: 0;
    z-index: 2;
    transition: all 0.2s ease;

    @apply opacity-0 absolute p-2 bg-primary text-white hidden;

    .internal-actions {
      a {
        color: inherit;
      }
    }
  }

  &.small {
    .control-action-div {
      top: -28px;
      padding: 0.2rem;
    }
  }

  &.active {
    &:not(.transparent) {
      outline: 1px dashed var(--primary);
    }

    .control-action-div {
      @apply opacity-100 block;
    }
  }
}
</style>
