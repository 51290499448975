<template>
  <div
    v-if="isTrialExpired || isLicenseExpired"
    class="flex flex-col flex-1 items-center justify-center"
  >
    <h2 class="text-secondary-red">
      {{
        $t('license_expired_contact_us', {
          type: isTrialExpired ? $t('trial') : $tc('license'),
        })
      }}
    </h2>
    <h2>
      <a :href="`mailto:${$t('sales_email')}`">{{ $t('sales_email') }}</a>
    </h2>
    <h2>
      <a :href="`tel:${$t('sales_phone')}`">{{ $t('sales_phone') }}</a>
    </h2>
  </div>
  <div v-else class="flex flex-col flex-1">
    <slot />
  </div>
</template>

<script>
import CloneDeep from 'lodash/cloneDeep'
import { LicenseComputed, LicenseMethods } from '@state/modules/license'
export default {
  name: 'FlotoLicenseChecker',
  data() {
    this.currentInterval = null
    return {}
  },
  computed: {
    ...LicenseComputed,
  },
  watch: {
    isTrialExpired(newValue) {
      if (newValue === false) {
        if (this.currentInterval) {
          clearInterval(this.currentInterval)
          this.currentInterval = null
        }
      }
    },
    isLicenseExpired(newValue) {
      if (newValue === false) {
        if (this.currentInterval) {
          clearInterval(this.currentInterval)
          this.currentInterval = null
        }
      }
    },
  },
  created() {
    this.fetchLicense()
    if (!this.isTrialExpired && !this.isLicenseExpired) {
      this.currentInterval = setInterval(() => {
        this.resetLicense(CloneDeep(this.license))
      }, 60000)
    }
  },
  beforeDestroy() {
    if (this.currentInterval) {
      clearInterval(this.currentInterval)
      this.currentInterval = null
    }
  },
  methods: {
    ...LicenseMethods,
  },
}
</script>
