<template>
  <div class="inline-block relative" :class="{ 'pr-2': showOnlineStatus }">
    <MTooltip v-if="showTooltip">
      <template v-slot:trigger>
        <span>
          <MAvatar
            :size="size"
            :icon="icon"
            :src="userAvatar"
            :class="{ calling: isCalling }"
            v-bind="$attrs"
          >
            <slot />
          </MAvatar>
        </span>
      </template>
      {{ fullUser.name || defaultText }}
    </MTooltip>
    <MAvatar
      v-else
      :size="size"
      :icon="icon"
      :src="userAvatar"
      :class="{ calling: isCalling, 'on-call': onCall }"
    >
      <slot />
    </MAvatar>
    <div
      v-if="showOnlineStatus"
      class="chat-status"
      :class="{ online: isOnline }"
    />
    <slot name="after-avatar" :user="fullUser"></slot>
  </div>
</template>

<script>
import { TechnicianComputed } from '@state/modules/technician'
import { getGlobalUserApi } from '@modules/users/users-api'
export default {
  name: 'FlotoUserAvatar',
  props: {
    userId: { type: Number, default: undefined },
    size: { type: [String, Number], default: undefined },
    avatar: { type: String, default: undefined },
    icon: { type: String, default: 'user' },
    showOnlineStatus: { type: Boolean, default: false },
    isOnline: { type: Boolean, default: false },
    isCalling: { type: Boolean, default: false },
    onCall: { type: Boolean, default: false },
    showTooltip: { type: Boolean, default: false },
    defaultText: {
      type: String,
      default() {
        return this.$tc('system')
      },
    },
  },
  data() {
    this.systemUserId = -93102
    return {
      fullUser: {},
      loading: true,
    }
  },
  computed: {
    ...TechnicianComputed,
    mainUser() {
      return this.fullUser
    },
    userAvatar() {
      if (this.avatar) {
        return this.avatar
      }
      if (!this.userId) {
        return undefined
      }
      return this.fullUser.avatar
      // const t = this.technician(this.userId)
      // if (t) {
      //   return t.avatar
      // }
    },
  },
  created() {
    if (this.userId) {
      const user = this.technician(this.userId)
      if (user && user.id) {
        this.fullUser = user
        this.loading = false
      } else {
        if (this.userId === this.systemUserId) {
          this.loading = false
          return
        }
        getGlobalUserApi(this.userId, { archived: true }).then((user) => {
          this.fullUser = user
          this.loading = false
        })
      }
    }
  },
}
</script>

<style lang="less" scoped>
.chat-status {
  position: absolute;
  right: -5px;
  bottom: 0;
  width: 10px;
  min-width: 10px;
  height: 10px;
  background-color: @neutral-light;
  border-radius: 50%;

  &.online {
    background-color: @success-color;
  }
}
.@{ant-prefix}-avatar.@{ant-prefix}-avatar-circle {
  &.calling {
    border-radius: 100%;
    animation: play 2s ease infinite;
    backface-visibility: hidden;
    backface-visibility: hidden;
    backface-visibility: hidden;
    backface-visibility: hidden;
  }

  &.on-call {
    border-radius: 100%;
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.4),
      0 0 0 20px rgba(255, 255, 255, 0.2);
  }
}

@keyframes play {
  0% {
    transform: scale(1);
  }

  15% {
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.4);
  }

  25% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.4),
      0 0 0 20px rgba(255, 255, 255, 0.2);
  }

  25% {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.4),
      0 0 0 30px rgba(255, 255, 255, 0.2);
  }
}
</style>
